import { connect } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import { useEffect, useState, useRef, forwardRef } from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import globalStyles from "Styles/globalStyles";
import {
  updateFilterConfig,
  resetFilterConfigSelections,
  getFilterConfigsTableData,
  getFilterConfigById,
  setModuleConfigData,
  getFilteredFields,
  setFilteredFields,
  setMandatoryFieldsInScreen,
  deleteFilterConfigById,
} from "../../../../actions/filterAction";
import { addSnack } from "../../../../actions/snackbarActions";
import {
  saveIngestionConfig,
  saveIngestionSourcingConfig,
} from "../../../../actions/tenantConfigActions";
import moment from "moment";
import Tabs from "commonComponents/tabs";
import {
  flattenSelectedFiltersData,
  formatTabsArray,
  checkForEmptyDimensionFilters,
} from "../../../../pages/filters/components/common-functions";
// import AgGrid from "Utils/agGrid";
import AgGridComponent from "Utils/agGrid";
import agGridColumnFormatter from "Utils/agGrid/column-formatter";
import LoadingOverlay from "Utils/Loader/loader";
import ConfirmPrompt from "commonComponents/confirmPrompt";
import {
  DATAINGESTION_TENANT_CONFIG_GET_ALL,
  INGESTION_CONFIG_GET_ALL,
} from "modules/dataIngestion/constants-dataIngestion/apiConstants";
import axiosPlatformInstance from "Utils/axiosPlatform";
import { homePageActions } from "actions/homePageActions";
import { EDIT } from "modules/dataIngestion/constants-dataIngestion/moduleConstants";
import { Box } from "@mui/system";
import { getOptions } from "Utils/functions/utils";
import { setConfigCompleted, updateSubModuleStatus } from "actions/dataIngestionActions";

const useStyles = makeStyles({
  moduleTitle: {
    fontSize: 20,
    fontWeight: 500,
  },
  dialogActions: {
    background: "#F9F9F9",
    marginRight: "2%",
  },
  container: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "flex-end",
  },
  toggleCellStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  autoGeneratedCells: {
    backgroundColor: "lightyellow",
  },
});
const EditModal = forwardRef((props, ref) => {
  const classes = useStyles();
  const [updateFilterConfigLoader, setupdateFilterConfigLoader] = useState(
    false
  );
  const [editFilterDimensions, seteditFilterDimensions] = useState([]);
  const [updateConfigData, setupdateConfigData] = useState({}); //State variable for edit config API response details
  const update = async () => {
    if (
      checkForEmptyDimensionFilters(
        props.selectedModuleConfigData,
        editFilterDimensions
      )
    ) {
      props.addSnack({
        message: "Please add atleast one filter for the chosen dimension(s)",
        options: {
          variant: "error",
        },
      });
      return;
    }
    const elements = flattenSelectedFiltersData(props.selectedModuleConfigData);
    const configJSON = {
      name: props.configObj.name,
      screens: updateConfigData.screens,
      elements: elements,
    };
    try {
      setupdateFilterConfigLoader(true);
      await props.updateFilterConfig(
        props.id,
        props.configObj.fc_code,
        configJSON
      );
      props.addSnack({
        message: "Configuration updated successfully",
        options: {
          variant: "success",
        },
      });
      ref.current.api.refreshServerSideStore({ purge: true });
      setupdateFilterConfigLoader(false);
      props.handleClose();
    } catch (error) {
      setupdateFilterConfigLoader(false);
      props.addSnack({
        message: "Failed to update",
        options: {
          variant: "error",
        },
      });
    }
  };

  useEffect(() => {
    const fetchConfigInfo = async () => {
      setupdateFilterConfigLoader(true);
      const res = await props.getFilterConfigById(props.configObj.fc_code);
      const configObj = res.data.data;
      const dimensions = configObj?.dimensions;
      const screens = props.configObj.screens;
      setupdateConfigData(configObj); //after fetching the information from the API related to the config
      //set the object into the state variable
      const fieldsres = await props.getFilteredFields(dimensions, screens);
      props.setFilteredFields(fieldsres.data.data.filters);
      props.setMandatoryFieldsInScreen(fieldsres.data.data.compulsory);
      dimensions.forEach((dimension) => {
        const eachDimensionFilters = configObj?.elements.filter(
          (element) => element.dimension === dimension
        );
        props.setModuleConfigData(eachDimensionFilters, dimension);
      });
      seteditFilterDimensions(
        configObj?.dimensions.map((dimension) => {
          return {
            label: dimension,
            value: dimension,
          };
        })
      );
      setupdateFilterConfigLoader(false);
    };
    //Once the modal is opened fetch the config details
    fetchConfigInfo();
  }, []);
  return (
    <Dialog
      id="editFilterDialog"
      aria-labelledby="customized-dialog-title"
      open={props.open}
      maxWidth="md"
      fullWidth={true}
      disableEscapeKeyDown={true}
      onClose={(_event, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        props.handleClose();
      }}
      classes={{
        paperFullWidth: classes.paperFullWidth,
      }}
    >
      {updateFilterConfigLoader && <LinearProgress />}
      <DialogContent
        dividers
        classes={{
          root: classes.dialogContentRoot,
        }}
      >
        <Typography classes={{ root: classes.moduleTitle }}>
          {props.configObj.name}
        </Typography>
        <Tabs tabsData={formatTabsArray(editFilterDimensions, props.id)} />
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <>
          {" "}
          <Button
            disabled={updateFilterConfigLoader}
            color="primary"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            color="primary"
            variant="contained"
            onClick={update}
            disabled={updateFilterConfigLoader}
          >
            Update Filters
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
});
const DataIngestionConfigTable = (props) => {
  const globalClasses = globalStyles();
  const classes = useStyles();
  const [isLoading, setLoadingStatus] = useState(false);
  const [selectedConfig, setselectedConfig] = useState("");
  const [screenConfig, setScreenConfig] = useState([]);
  const [open, setOpen] = useState(false);
  const [filterConfigsTableColumns, setfilterConfigsTableColumns] = useState(
    []
  );
  const [showDeleteConfirmModal, updateShowDeleteConfirmModal] = useState(
    false
  );
  const [currentConfigToDelete, updateCurrentConfigToDelete] = useState(null);
  const configTableRef = useRef(null);
  const [configTableData, setConfigTableData] = useState([]);
  const [savePayloadData, setSavePayloadData] = useState([]);

  const handleClose = () => {
    props.resetFilterConfigSelections();
    setOpen(false);
  };

  const manualCallBack = async (body, pageIndex, params) => {
    const manualbody = {
      filters: body,
    };
    // const response = await props.getFilterConfigsTableData(
    //   manualbody,
    //   pageIndex,
    //   10
    // );
    let data = await getTenantFilterConfig();
    return {
      totalCount: data.data.length,
      data: data.data,
    };
  };

  const getTenantFilterConfig = async () => {
    try {
      setLoadingStatus(true);
      const { data } = await axiosPlatformInstance({
        url:
          props.location === "/data-platform/configuration"
            ? `${INGESTION_CONFIG_GET_ALL}`
            : `${DATAINGESTION_TENANT_CONFIG_GET_ALL}`,
        method: "GET",
      });
      setLoadingStatus(false);
      return data;
    } catch (e) {
      setLoadingStatus(false);
      if (e.response.status === 403) {
        displaySnackMessages(e.response.data.message, "error");
      } else {
        displaySnackMessages("Something went wrong", "error");
      }
    }
  };

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  const getColumns = (
    queryParams,
    levelsJson = {},
    actions = {},
    formatSetAllLabel = false
  ) => {
    const userPermission = props.enabledApplicationsPermission.filter(
      (module) => module.module_id === 2
    );
    const data = {
      total: null,
      page: null,
      count: null,
      status: true,
      data: [
        {
          sub_headers: [],
          tc_code: 1,
          label: "Configuration name",
          column_name: "attribute_name",
          dimension: "Product",
          type: "str",
          is_frozen: false,
          is_editable: false,
          is_aggregated: false,
          order_of_display: 1,
          is_hidden: false,
          is_required: false,
          tc_mapping_code: 15,
          aggregate_type: "",
          formatter: "",
          is_row_span: false,
          footer: "",
          is_searchable: false,
          extra: {},
          sortable:true,
          width: 200,
        },
        {
          sub_headers: [],
          tc_code: 2,
          label: "Value",
          column_name: "attribute_value",
          dimension: "Product",
          type: "dynamic_cell",
          is_frozen: false,
          is_editable: props.userRole[EDIT] ? true : false,
          is_aggregated: false,
          order_of_display: 1,
          is_hidden: false,
          is_required: false,
          tc_mapping_code: 15,
          aggregate_type: "",
          formatter: "",
          is_row_span: false,
          footer: "",
          is_searchable: false,
          isSearchable: true,
          extra: {},
          isMulti: false,
          sortable: false,
          width: 200,
          minWidth: 200,
        },
        {
          sub_headers: [],
          tc_code: 3,
          label: "Description",
          column_name: "description",
          dimension: "Product",
          type: "str",
          is_frozen: false,
          is_editable: false,
          is_aggregated: false,
          order_of_display: 1,
          is_hidden: false,
          is_required: false,
          tc_mapping_code: 15,
          aggregate_type: "",
          formatter: "",
          is_row_span: false,
          footer: "",
          is_searchable: false,
          extra: {},
          sortable: false,
          width: 200,
        },
        {
          sub_headers: [],
          tc_code: 4,
          label: "Created by",
          column_name: "created_by",
          dimension: "Product",
          type: "str",
          is_frozen: false,
          is_editable: false,
          is_aggregated: false,
          order_of_display: 4,
          is_hidden: false,
          is_required: false,
          tc_mapping_code: 32,
          aggregate_type: "",
          formatter: "",
          is_row_span: false,
          footer: "",
          is_searchable: false,
          extra: {},
          sortable: false,
          width: 200,
        },
        {
          sub_headers: [],
          tc_code: 5,
          label: "Created on",
          column_name: "created_at",
          dimension: "Product",
          type: "str",
          is_frozen: false,
          is_editable: false,
          is_aggregated: false,
          order_of_display: 5,
          is_hidden: false,
          is_required: false,
          tc_mapping_code: 33,
          aggregate_type: "",
          formatter: "",
          is_row_span: false,
          footer: "",
          is_searchable: false,
          extra: {},
          sortable: false,
          width: 200,
        },
      ],
      message: "Successful",
      previous: null,
      next: null,
      offset: null,
    };
    return agGridColumnFormatter(
      data.data,
      levelsJson,
      actions,
      formatSetAllLabel
    );
  };
  useEffect(async () => {
    if (!props.enabledApplicationsPermission.length) {
      await props.setUserApps();
    }
  }, []);

  useEffect(()=>{
    return ()=>{
      props.setConfigCompleted(null)
    }
  },[])

  useEffect(() => {
    let cols = getColumnsAccessDetail();
    setfilterConfigsTableColumns(cols);
  }, [props.userRole]);

  useEffect(() => {
    const fetchFilterConfigTableColumns = async () => {
      let data = await getTenantFilterConfig();
      let auto_generated_config = data.data
        .filter((config) => config?.auto_generated)
        .map((data) => {
          return {
            attribute_name: data.attribute_name,
            attribute_value: checkValueDataType(data),
            datatype: data.metadata.datatype,
            auto_generated: data.auto_generated,
            display_name: data.display_name,
            is_mandatory: data.is_mandatory,
            module: data.module,
            description: data.description,
          };
        });
      props.updateSubModuleStatus(auto_generated_config.length ? true : false)
      setSavePayloadData([...auto_generated_config]);
      props.setConfigCompleted(Math.round(((data.data.length-auto_generated_config.length)/data.data.length)*100))
      props.getQueryAttributes(data);
      let tableData = data.data.map((row) => {
        let options = getOptions(row?.metadata?.options);
        let isMulti =
          row?.metadata?.datatype === "str" && row?.metadata?.options.length
            ? false
            : true;
        if (row?.metadata)
          return {
            ...row,
            // ...row.metadata,
            options,
            isMulti: isMulti,
            attribute_value: 
              row.metadata.datatype === "list"
                ? row.metadata.options.length 
                  ? getOptions(JSON.parse(row.attribute_value))
                  : JSON.parse(row.attribute_value.trim().length > 0 ? row.attribute_value : "[]")
                : row.attribute_value,
          };
        return {
          ...row,
          isMulti: isMulti,
          attribute_value:
            row.datatype === "list" 
              ? row.options.length
                ? getOptions(JSON.parse(row.attribute_value))
                : JSON.parse(row.attribute_value)
              : row.attribute_value,
        };
      });
      setConfigTableData(Object.keys(data.data).length ? tableData : []);
      // let cols = await getColumnsAg("table_name=filter_configurations")();
    };
    fetchFilterConfigTableColumns();
  }, []);

  const cellStyle = (params) => {
    let styles = {};
    if (params.data?.metadata?.datatype === "bool" && props.userRole[EDIT]) {
      styles = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      };
    }
    if (params.data.auto_generated) styles.backgroundColor = "lightyellow";

    return true ? styles : {};
  };

  const getColumnsAccessDetail = () => {
    let cols = getColumns();
    
    cols = cols.map((col) => {
      if (col.column_name === "attribute_value") {
        col.cellStyle = cellStyle;
        if(!props.userRole[EDIT]){
          col.cellRenderer=(params) => {
            let renderVal = params?.data?.metadata?.datatype === "list" ? params?.data?.options.length  
              ? JSON.stringify(params?.data?.attribute_value.map(list=>list?.label)) 
              : JSON.stringify(params?.data?.attribute_value) : params?.data?.attribute_value
            return (<>
            {renderVal}
            </>)
          }
        }
      }
      if (col.column_name === "created_at") {
        col.accessor = "created_at";
        col.field = "created_at";
        col.cellRenderer = (params) => {
          return (
            <>{moment(params.data.created_at).format("MMM Do YY, hh:mm a")}</>
          );
        };
      }
      return col;
    });
    return cols;
  };

  const deleteFilterConfig = async (data) => {
    try {
      setLoadingStatus(true);
      await props.deleteFilterConfigById(data.data.fc_code);
      setLoadingStatus(false);
      props.addSnack({
        message: "Deleted filter configuration successfully",
        options: {
          variant: "success",
        },
      });
      configTableRef.current.api.refreshServerSideStore({ purge: true });
    } catch (error) {
      setLoadingStatus(false);
      props.addSnack({
        message: "Failed to delete the filter configuration",
        options: {
          variant: "error",
        },
      });
    }
  };

  const onCellValueChanged = (params, nestingLevel) => {
    const { value, rowIndex, oldValue, colDef } = params;
    // console.log(value, rowIndex, oldValue, colDef,params)
    // if (oldValue !== value) {
    //   let tableData = cloneDeep([]);
    //   params.api.forEachNode((node) => {
    //     // console.log(node.data.attribute_name)
    //     // if(colDef.field === node.data["attribute_name"])
    //       tableData.push(node.data);
    //   });
    //   console.log(tableData[rowIndex],[colDef.field],value)
    //   tableData[rowIndex] = {
    //     ...tableData[rowIndex],
    //     [colDef.field]: value,
    //   };
    //   // props.onTableCellUpdate(tableData, nestingLevel);
    //   console.log(cloneDeep(tableData))
    //   setScreenConfig(tableData)
    // }
    // onSaveHandler(params.data)
    let validate = validateInput(params.data);
    if (validate) {
      let payload = [...savePayloadData];
      if (!payload.length) {
        payload.push({
          attribute_name: params.data.attribute_name,
          attribute_value: checkValueDataType(params.data),
          datatype: params.data.datatype,
          auto_generated: params.data.auto_generated
        });
      } else {
        let index = payload.findIndex(
          (data) => data.attribute_name === params.data.attribute_name
        );
        if (index !== -1)
          payload[index].attribute_value = checkValueDataType(params.data);
        else {
          payload.push({
            attribute_name: params.data.attribute_name,
            attribute_value: checkValueDataType(params.data),
            datatype: params.data.datatype,
            auto_generated: params.data.auto_generated
          });
        }
      }

      setSavePayloadData(payload);
    }
  };

  const validateInput = (data) => {
    let value = JSON.stringify(data?.attribute_value).trim();
    if (value) {
      if (data.metadata.datatype === "bool") {
        if (["true", "false"].includes(value.toLowerCase())) {
          return true;
        }
        displaySnackMessages(
          "Enter valid " + `${data.attribute_name}` + " boolean Value",
          "error"
        );
        return false;
      }
      if (data.metadata.datatype === "int") {
        if (value.match(/^\d+$/)) return true;
        displaySnackMessages(
          "Enter valid " + `${data.attribute_name}` + " integer value",
          "error"
        );
        return false;
      }
      if (data.metadata.datatype === "str") {
        if (value.length >= 1) return true;
        displaySnackMessages(
          "Enter valid " + `${data.attribute_name}` + " value",
          "error"
        );
        return false;
      }
      if (data.metadata.datatype === "list") {
        try {
          if (value.length >= 1 && JSON.parse(value)) return true;
        } catch (error) {
          displaySnackMessages(
            "Enter valid " + `${data.attribute_name}` + " value",
            "error"
          );
          return false;
        }
        displaySnackMessages(
          "Enter valid " + `${data.attribute_name}` + " value",
          "error"
        );
        return false;
      }
      return true;
    } else {
      if (data?.is_mandatory) {
        displaySnackMessages(
          "Enter valid " + `${data.attribute_name}` + " value",
          "error"
        );
        return false;
      }

      return true;
    }
  };

  const checkValueDataType = (payload) => {
    if (!["list", "dict"].includes(payload.metadata.datatype)) {
      if (payload.metadata.datatype === "int") return parseInt(payload.attribute_value);
      else if (payload.metadata.datatype === "float")
        return parseFloat(payload.attribute_value);
      else if (payload.metadata.datatype === "date") {
        let output = new Date(payload.attribute_value);
        var dateString = moment(output).format("YYYY-MM-DD");
        return dateString;
      } 
      else if (payload.metadata.datatype === "time") {
        var timeString = moment(payload.attribute_value).format("HH:mm");
        return timeString;
      }
      else if (payload.metadata.datatype === "timestamp") {
        let output = new Date(payload.attribute_value);
        return output.toISOString();
      } else if (payload.metadata.datatype === "bool")
        return payload.attribute_value + "".toLowerCase() === "true"
          ? true
          : false;
      return payload.attribute_value;
    }
    else if(payload.metadata.datatype === "list") {
      let attribute_value  = Array.isArray(payload.attribute_value) ? payload.attribute_value : payload.attribute_value.length < 2 ? [] : JSON.parse(payload.attribute_value)
      let value = attribute_value.map(list=>list.label?list.label:list)
      return value.filter(function(item, pos) {
        return value.indexOf(item) == pos;
    })
    }
    return payload.attribute_value;
  };

  const onSaveHandler = () => {
    // let validate = validateInput(payload)
    const saveTenantFilterConfig = async () => {
      if (!screenConfig) return;
      try {
        let saveTenantFilterConfigPayload = {
          // config_value: screenConfig,
          update_config: savePayloadData,
          // ...payload
        };
        setLoadingStatus(true);
        let tenantFilterConfigResponse =
          props.location === "/data-platform/configuration"
            ? await props.saveIngestionConfig(
                // props.appInfo?.applicationCode,
                0,
                saveTenantFilterConfigPayload
              )
            : await props.saveIngestionSourcingConfig(
                // props.appInfo?.applicationCode,
                0,
                saveTenantFilterConfigPayload
              );
        setLoadingStatus(false);
        if (tenantFilterConfigResponse?.status) {
          props.addSnack({
            message:
              tenantFilterConfigResponse?.message ||
              "Successful created new configuration",
            options: {
              variant: "success",
            },
          });
          props.renderTable();
        } else {
          props.addSnack({
            message:
              tenantFilterConfigResponse?.message ||
              "Updating new configuration failed!",
            options: {
              variant: "error",
            },
          });
        }
        props.renderTable();
        // setLoading(false);
      } catch (e) {
        // setLoading(false);
        setLoadingStatus(false);
        props.addSnack({
          message: e?.message || "Updating new configuration failed!",
          options: {
            variant: "error",
          },
        });
      }
    };
    // if(validate) {
    saveTenantFilterConfig();
    // }
  };

  return (
    <>
      <LoadingOverlay loader={isLoading} spinner>
        <ConfirmPrompt
          showModal={showDeleteConfirmModal}
          title="Confirm Delete"
          message="Are you sure you want to delete the config ?"
          ariaLabeledBy="save-changes-dialog"
          primaryBtnText="Yes"
          secondaryBtnText="No"
          setConfirm={updateShowDeleteConfirmModal}
          confirmCallback={(val) => {
            if (val) {
              deleteFilterConfig(currentConfigToDelete);
            }
            updateCurrentConfigToDelete(null);
          }}
        />
        {filterConfigsTableColumns.length > 0 && (
          <AgGridComponent
            onCellValueChanged={(params) => onCellValueChanged(params)}
            columns={filterConfigsTableColumns}
            rowdata={configTableData}
            loadTableInstance={(gridInstance) => {
              configTableRef.current = gridInstance;
            }}
            sizeColumnsToFitFlag={true}
          />
        )}
        {props.userRole[EDIT] && (
          <>
            <div className={classes.container}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onSaveHandler()}
                disabled={!savePayloadData.length}
                // startIcon={<AddIcon />}
              >
                Save
              </Button>
            </div>
            <Typography sx={{ fontStyle: "italic", m: 1 }}>
              <Box component="span" fontWeight="bold" color={"red"}>
                Note :{" "}
              </Box>{" "}
              Highlighted fields represent auto generated values*
            </Typography>
          </>
        )}
        {open && (
          <EditModal
            ref={configTableRef}
            open={open}
            configObj={selectedConfig}
            createdConfigs={props.configs.data}
            page={props.configs.page}
            handleClose={handleClose}
            updateFilterConfig={props.updateFilterConfig}
            resetFilterConfigSelections={props.resetFilterConfigSelections}
            id={props.id}
            addSnack={props.addSnack}
            selectedModuleConfigData={props.selectedModuleConfigData}
            getFilterConfigsTableData={props.getFilterConfigsTableData}
            getFilterConfigById={props.getFilterConfigById}
            setModuleConfigData={props.setModuleConfigData}
            getFilteredFields={props.getFilteredFields}
            setFilteredFields={props.setFilteredFields}
            setMandatoryFieldsInScreen={props.setMandatoryFieldsInScreen}
          />
        )}
      </LoadingOverlay>
    </>
  );
};

DataIngestionConfigTable.propTypes = {
  setConfigCompleted: PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    createdConfigs: state.filterElementsReducer.createdConfigs,
    configs: state.filterElementsReducer.configs,
    selectedModuleConfigData:
      state.filterElementsReducer.selectedModuleConfigData,
    enabledApplicationsPermission: state.homePageReducer.userAppsPermission,
  };
};
const mapActionsToProps = {
  updateFilterConfig,
  resetFilterConfigSelections,
  getFilterConfigsTableData,
  addSnack,
  saveIngestionConfig,
  saveIngestionSourcingConfig,
  getFilterConfigById,
  setModuleConfigData,
  getFilteredFields,
  setFilteredFields,
  setMandatoryFieldsInScreen,
  deleteFilterConfigById,
  setUserApps: homePageActions,
  updateSubModuleStatus,
  setConfigCompleted
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(DataIngestionConfigTable);
