import { GET_DATA_SOURCES, SET_ACTIVE_MODULE, SET_CONFIG_COMPLETED, SET_DATA_INGESTION_WSCLIENT, SET_DATA_SOURCES, SET_INGESTION_BUTTON, SET_INGESTION_DATA, SET_INGESTION_FAILED_STATUS_FLAG, SET_INGESTION_PAUSE_STATUS_FLAG, SET_INGESTION_PROCESS_SWITCH_STATUS, SET_MODULE_INFO, UPDATE_SUBMODULE_STATUS } from "./types";

export const dataIngestionActions = (data) => async (dispatch) => {
  dispatch({
    type: SET_DATA_SOURCES,
    payload: data,
  });
}

export const getDataSources = () => async(dispatch) => {
  dispatch({
    type: GET_DATA_SOURCES
  });
}

export const setWebSocketClient = (client) => (dispatch) => {
  dispatch({
    type: SET_DATA_INGESTION_WSCLIENT,
    payload: {
      wsClient: client,
    },
  });
};

export const setIngestionData = (ingestionData) => (dispatch) => {
  dispatch({
    type: SET_INGESTION_DATA,
    payload: {
      results: ingestionData.data,
      
    },
  });
};

export const updateSubModuleStatus = (status) => (dispatch) => {
  dispatch({
    type: UPDATE_SUBMODULE_STATUS,
    payload: {
      status: status,
      
    },
  });
};

export const setModuleInfo = (data) => (dispatch) => {
  dispatch({
    type: SET_MODULE_INFO,
    payload: {
      results: data,
      
    },
  });
};

export const setActiveModule = (data) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_MODULE,
    payload: {
      results: data,
      
    },
  });
};

export const setIngestionProcessSwitchStatus = (data) => (dispatch) => {
  dispatch({
    type: SET_INGESTION_PROCESS_SWITCH_STATUS,
    payload: {
      results: data,     
    },
  });
};

export const setIngestionButton = (data) => (dispatch) => {
  dispatch({
    type: SET_INGESTION_BUTTON,
    payload: {
      results: data,     
    },
  });
};
  
export const setFailedStatusFlag = (data) => (dispatch) => {
  dispatch({
    type: SET_INGESTION_FAILED_STATUS_FLAG,
    payload: {
      results: data,     
    },
  });
};

export const setPausedStatusFlag = (data) => (dispatch) => {
  dispatch({
    type: SET_INGESTION_PAUSE_STATUS_FLAG,
    payload: {
      results: data,     
    },
  });
}

  export const setConfigCompleted = (data) => (dispatch) => {
    dispatch({
      type: SET_CONFIG_COMPLETED,
      payload: {
        results: data,     
      },
    });
};
  

