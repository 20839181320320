import { GET_DATA_SOURCES, SET_ACTIVE_MODULE, SET_CONFIG_COMPLETED, SET_DATA_INGESTION_WSCLIENT, SET_DATA_SOURCES, SET_INGESTION_BUTTON, SET_INGESTION_DATA, SET_INGESTION_FAILED_STATUS_FLAG, SET_INGESTION_PAUSE_STATUS_FLAG, SET_INGESTION_PROCESS_SWITCH_STATUS, SET_MODULE_INFO, UPDATE_SUBMODULE_STATUS } from "../actions/types";

export const initialState = {
  dataSourcesDetails: [],
  ingestionData: [],
  wsClient: null,
  subModuleStatus: true,
  moduleInfo:{},
  activeModule: 'Data Extraction',
  ingestionProcessSwitchFlag: false,
  ingestionButton: null,
  failedStatusFlag:false,
  pauseFlag:false,
  configCompleted:null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_DATA_SOURCES:
      return {
        ...state,
        dataSourcesDetails: action.payload,
      };
    case GET_DATA_SOURCES:
      return {
        ...state,
      };
    case SET_DATA_INGESTION_WSCLIENT:
      return {
        ...state,
        wsClient: action.payload.wsClient,
      };
    case SET_INGESTION_DATA:
      return {
        ...state,
        ingestionData: [...action.payload.results],
      };
    case UPDATE_SUBMODULE_STATUS:
      return {
        ...state,
        subModuleStatus : action.payload.status,
      };
      case SET_MODULE_INFO:
        return {
          ...state,
          moduleInfo: {...action.payload.results},
        };
      case SET_ACTIVE_MODULE:
        return {
          ...state,
          activeModule: action.payload.results,
        };
      case SET_INGESTION_PROCESS_SWITCH_STATUS:
        return {
          ...state,
          ingestionProcessSwitchFlag : action.payload.results,
        };
      case SET_INGESTION_BUTTON:
        return {
          ...state,
          ingestionButton : action.payload.results,
        };
      case SET_INGESTION_FAILED_STATUS_FLAG:
        return {
          ...state,
          failedStatusFlag : action.payload.results,
        };
      case SET_INGESTION_PAUSE_STATUS_FLAG:
        return {
          ...state,
          pauseFlag : action.payload.results,
        };
        case SET_CONFIG_COMPLETED:
          return {
            ...state,
            configCompleted : action.payload.results,
          };  
      default:
      return state;
  }
}
